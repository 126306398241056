<template>
  <section>
    <Toast />
    <div class="bg-white p-4 rounded-md w-full mt-2">
      <div class="w-full lg:flex">
        <div class="w-full lg:w-5/12">
          <div class="flex gap-2 items-center">
            <i class="pi pi-copy text-blue-800 text-xl"></i>
            <div>
              <p class="font-bold text-blue-800 text-xl">Orden de venta</p>
              <p class="font-bold text-blue-800 text-xl">{{ $route.params.id }}</p>
              <div class="flex gap-2 text-green-800" v-if="ordenesStore._orden.sincronizacionSap && ordenesStore._orden.sincronizacionSap.Status === 2">
                <p class=""># OV SAP:</p>
                <span class="font-bold">{{ ordenesStore._orden.sincronizacionSap.DocNum }}</span>
              </div>
              <div class="flex gap-2 text-green-800" v-if="ordenesStore._orden.pago_sincronizado_sap">
                <p class="">Pago sincronizado SAP:</p>
                <span class="font-bold">{{ ordenesStore._orden.sincronizacionPagoSap.DocNum }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-7/12 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Estado</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.DocStatus === 0 ? 'Abierta' : (ordenesStore._orden.DocStatus === 1 ? 'Cerrada' : 'Cancelada') }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Fecha Cont./Doc</p>
            <p class="font-bold text-blue-800">{{ dayjs(ordenesStore._orden.FechaDocumento).format('YYYY/MM/DD') }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Cliente</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.Cliente.CardName }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Bodega</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.U_PHR_Bodega ? ordenesStore._orden.U_PHR_Bodega : 'N/A' }}</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.Bodega ? ordenesStore._orden.Bodega.WhsName : 'N/A' }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Paciente</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.U_ACS_TpoIdentf + ' - ' + ordenesStore._orden.U_PHR_NumDcto }}</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.Paciente.U_ACS_NmbPct }}</p>
          </div>
        </div>
      </div>
      <div class="w-full shadow-md rounded-md p-2">
        <div class="w-full flex text-blue-800 gap-2">
          <button @click="showUdf = !showUdf"><i :class="showUdf ? 'pi-chevron-down' : 'pi-chevron-right'" class="pi text-xs"></i></button>
          <p class="font-bold">UDF</p>
        </div>
        <div v-if="showUdf" class="w-full grid grid-cols-1 md:grid-cols-4 lg:grid-cols-10 gap-4 mt-2">
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Motivo Autorización:</p>
            <p class="font-bold text-gray-600">{{ ordenesStore._orden.MotAut ? ordenesStore._orden.MotAut.Nombre : 'N/A' }}</p>
          </div>
          <div v-tooltip="`${ordenesStore._orden.DiagPpal.DesCie}`" class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Diag. Principal:</p>
            <p class="font-bold text-gray-600">{{ ordenesStore._orden.DiagPpal ? ordenesStore._orden.DiagPpal.CdCie : 'N/A' }}</p>
          </div>
          <div v-tooltip="`${ordenesStore._orden.DiagPpal.DesCie}`" class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Diag. Relacionado:</p>
            <p class="font-bold text-gray-600">{{ ordenesStore._orden.DiagSecu ? ordenesStore._orden.DiagSecu.CdCie : 'N/A' }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Régimen:</p>
            <p class="font-bold text-gray-600">{{ ordenesStore._orden.RegPac ? ordenesStore._orden.RegPac.nombre : 'N/A' }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Portabilidad:</p>
            <p class="font-bold text-gray-600">{{ ordenesStore._orden.U_PHR_Portabilidad ? ordenesStore._orden.U_PHR_Portabilidad == 0 ? 'NO' : 'SI' : 'N/A' }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Rango / Nivel:</p>
            <p class="font-bold text-gray-600">{{ ordenesStore._orden.Paciente ? ordenesStore._orden.Paciente.U_ACS_Regimen : 'N/A' }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">No. Pago Recibido:</p>
            <p class="font-bold text-gray-600">{{ ordenesStore._orden.U_PHR_NumPagoReci ? ordenesStore._orden.U_PHR_NumPagoReci.nombre : 'N/A' }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Cuota moderadora:</p>
            <p class="font-bold text-gray-600">{{ cuotas.cuota_moderadora }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Porcentaje Cuota:</p>
            <p class="font-bold text-gray-600">{{ cuotas.porcentaje_cuota }} %</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Zonificación:</p>
            <p style="overflow-wrap: anywhere;" class="font-bold text-gray-600">{{ ordenesStore._orden.U_PHR_Zonificacion ? ordenesStore._orden.U_PHR_Zonificacion : 'N/A' }}</p>
          </div>
        </div>
      </div>
      <div class="w-full mb-2 mt-2 shadow-md rounded-md p-2 flex flex-wrap lg:flex-auto gap-2">
        <Button @click="$router.push({ name: `pharmasan.ventas.ov.ver.tabla`, params: {id: $route.params.id} })" class="p-button-raised p-button-text p-button-success" icon="pi pi-home" />
        <Button @click="$router.push({ name: `pharmasan.ventas.ov.ver.mapa-relaciones`, params: {id: $route.params.id} })" icon="pi pi-sitemap" class="p-button-raised p-button-text" label="Mapa de relaciones" />
        <Button @click="$router.push({ name: `pharmasan.ventas.ov.ver.historial`, params: {id: $route.params.id} })" icon="pi pi-undo" class="p-button-raised p-button-warning p-button-text" label="Historial del documento" />
        <Button @click="validacionBotones('devolucion')" v-if="$can('pharmasan.ventas.ov.devoluciones')"  icon="pi pi-clone" class="p-button-raised p-button-danger p-button-text" label="Devolución" />
        <Button @click="validacionBotones('editar')" v-if="$can('pharmasan.ventas.ov.editar')" icon="pi pi-pencil" class="p-button-raised p-button-help p-button-text" label="Editar" />
        <Button @click="validacionBotones('cancelar')" v-if="$can('pharmasan.ventas.ov.cancelar')" icon="pi pi-times" class="p-button-raised p-button-danger p-button-text" label="Cancelar orden" />
        <Button @click="DescargarPdf(ordenesStore._orden.Id)" icon="pi pi-print" class="p-button-raised p-button-danger p-button-text" label="Imprimir orden" />
        <Button @click="displayModalImprimirOrden = true" class="p-button-raised p-button-danger p-button-text px-1">
          <i class="pi pi-print mr-2"></i>
          <i class="pi pi-cog"></i>
        </Button>
        <Button @click="DescargarNoEntrPdf(ordenesStore._orden.Id)" icon="pi pi-print" class="p-button-raised p-button-primary p-button-text" label="Imprimir No Entrega" />
      </div>
      <router-view></router-view>
      <Dialog headerClass="text-xs" header="Articulos Impresión" :closable="false" v-model:visible="displayModalImprimirOrden" :style="{width: '40vw'}" :modal="true">
      <div class="flex gap-2 mt-2 text-xs">
        <DataTable :value="listaMedicamentos" v-model:selection="lineasImprimir" dataKey="id_ovdt" responsiveLayout="scroll" >
          <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column header="Articulo" headerClass="text-xs">
            <template #body="slotProps">
              <div class="text-xs">
                <div class="flex gap-2">
                  <span class="flex gap-2 items-center">
                    {{ slotProps.data.ItemName }}
                  </span>
                </div>
                <div class="flex gap-2">
                  <p class="font-bold">Lote:</p><p class="font-bold text-gray-600">{{ slotProps.data.NumLote}}</p>
                  <p class="font-bold">Precio:</p><p class="font-bold text-green-600">{{ $h.formatCurrency(slotProps.data.UnitPrice) }}</p>
                </div>
              </div>
            </template>
          </Column>
          <Column header="Cant Entregada" headerClass="text-xs">
            <template #body="slotProps">
              <div class="text-xs">
                <span class="flex gap-2 items-center">
                  {{ slotProps.data.Quantity }}
                </span>
              </div>
            </template>
          </Column>
          <Column header="Cant Pendiente" headerClass="text-xs">
            <template #body="slotProps">
              <div class="text-xs">
                <span class="flex gap-2 items-center">
                  {{ slotProps.data.pendientes }}
                </span>
              </div>
            </template>
          </Column>
          <Column header="Cantidad impresión" headerClass="text-xs">
            <template #body="slotProps">
              <div class="text-xs" style="width: 60px;">
                <InputNumber v-model="slotProps.data.impresion" :max="parseInt(slotProps.data.Quantity)" :id="slotProps.data.Id" class="p-inputtext-sm w-full" inputClass="w-full" />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
      <template #footer>
        <Button class="p-button-outlined p-button-secondary" @click="displayModalImprimirOrden = false" label="Cerrar" />
        <Button class="p-button" @click="DescargarPdfConf($route.params.id)" label="Imprimir" />
      </template>
    </Dialog>
    </div>
  </section>
</template>
<script>
  import { onMounted, computed, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from '@/store'
  import { useToast } from 'primevue/usetoast'
  import { useOrdenesStore } from '../../../stores/ordenes.store'
  import { useMedicamentosStore } from '../../../stores/medicamentos.store'
  import Orden from '../../../services/orden.service'
  import dayjs from 'dayjs'
  import Cookies from 'js-cookie'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  export default {
    name: 'verOrdenVenta',
    setup () {
      // Servicios
      const _Orden = ref(new Orden())
      const toast = useToast()
      // Referencias
      const showUdf = ref(true)
      const showMedicamentos = ref(true)
      const showHistorialDocumento = ref(true)
      const expandedRows = ref(null)
      const route = useRoute()
      const router = useRouter()
      const dataMedicamento = ref({})
      const columnasTabla = ref([])
      const listadoMedicamentos = ref([])
      const displayModalInfoMedicamento = ref(false)
      const displayHistorialDocumento = ref(false)
      const lotesEntregar = ref([])
      const vista = ref('principal')
      const displayModalMedicamentoPendiente = ref(false)
      const displayModalMedicamentosPendientesListado = ref(false)
      const displayModalImprimirOrden = ref(false)
      const lineasImprimir = ref([])
      const lotePendienteSeleccionado = ref({})
      const validacionDevolucion = ref(0)
      const cuotas = ref({
        cuota_moderadora: 0,
        porcentaje_cuota: 0,
        valor_mayor_cuota_moderadora: 0
      })
      const events1 = ref([
          { status: 'Creación de la orden de venta', descripcion: 'Se genera la creación con 4 pendientes', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
          { status: 'Entrega', descripcion: 'Se genera la entrega con 2 pendientes', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
          { status: 'Entrega', descripcion: 'Se genera la entrega con 1 pendientes', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
          { status: 'Entrega', descripcion: 'Se genera la entrega total', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
      ])
      // Stores
      const storePersona = useStore()
      const ordenesStore = useOrdenesStore()
      const medicamentosStore = useMedicamentosStore()
      // Computadas
      const persona = storePersona.getters['auth/getPeople']
      const pagoSincronizado = computed(() => ordenesStore._pago_sincronizado_sap)
      const listaMedicamentos = computed(() => medicamentosStore._medicamentos_tabla)
      // Métodos
      const cancelarOrden = () => {
        if (medicamentosStore._medicamentos_tabla.some(e => e.Mipres && e.Mipres.infoestdireccionamiento === 'REPORTADO')) {
          return Swal.fire(
            'Error al cancelar',
            'No puede cancelar esta orden. Ya que contiene mipres con numeros de direccionamiento en estado REPORTADO',
            'error'
          )
        }
        _Orden.value.validarOvSync(route.params.id).then(({ data }) => {
          if (data) {
            Swal.fire(
              'Error al cancelar!',
              `Debe cancelar la orden en SAP con el número
              ${ordenesStore._orden.sincronizacionSap.DocNum}
              para poderla cancelar.`,
              'warning'
            )
          } else {
            Swal.fire({
            title: `<p style="line-height: initial">Estas seguro de cancelar la orden ${route.params.id} ?</p>`,
            text: 'Al realizar esta acción todos los lotes de los artículos que se encuentran entregados regresaran a stock',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Cancelar Orden',
            cancelButtonText: 'Cerrar',
            icon: 'warning'
          }).then((result) => {
            if (result.isDenied) {
              ordenesStore.cancelarOrden(route.params.id).then((response) => {
                const { data } = response
                if (!data) {
                  return Swal.fire(
                    'Error al cancelar',
                    `${response}`,
                    'error'
                  )
                } else {
                  Swal.fire(
                    'Cancelada!',
                    'La orden se ah cancelado con exito!',
                    'success'
                  ).then(() => {
                    router.push({ name: 'pharmasan.ventas.ov.listado' })
                  })
                }
              })
            }
          })
          }
        })
      }
      const DescargarPdf = (id) => {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA}/ov/generar-pdf/${id}`
        const token = JSON.parse(Cookies.get('user')).token
        const res = axios.getUri({ url, params: { token: `Bearer ${token}` } })
        window.open(res, '_blank')
      }
      const DescargarNoEntrPdf = (id) => {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA}/ov/no-entrega-pdf/${id}`
        const token = JSON.parse(Cookies.get('user')).token
        const res = axios.getUri({ url, params: { token: `Bearer ${token}` } })
        window.open(res, '_blank')
      }
      const DescargarPdfConf = (id) => {
        if (lineasImprimir.value.length) {
          let validlines = 0
          const lineas = lineasImprimir.value.map(m => {
            if (!m.impresion) {
              validlines = 1
              Swal.fire(
                'Error al Imprimir!',
                'Hay un item sin cantidad',
                'error'
              )
            }
            return { Id: m.id_ovdt, cant: m.impresion }
          })
          if (validlines === 0) {
            displayModalImprimirOrden.value = false
            const url = `${process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA}/ov/generar-pdf-conf/${id}`
            const token = JSON.parse(Cookies.get('user')).token
            const res = axios.getUri({ url, params: { token: `Bearer ${token}`, lineas } })
            window.open(res, '_blank')
            lineasImprimir.value = []
            listadoMedicamentos.value = listadoMedicamentos.value.map(m => {
              m.impresion = 0
              return m
            })
          }
        } else {
          Swal.fire(
            'impresión!',
            'Debe seleccionar al menos un articulo para imprimir!',
            'warning'
          )
        }
      }
      const validacionBotones = (tipo, id) => {
        if (tipo === 'devolucion') {
          // Si la ov esta cancelada
          if (ordenesStore._orden.DocStatus === 2) return toast.add({ severity: 'error', summary: 'Error', detail: 'La orden de venta se encuentra cancelada', life: 6000 })
          // Si la ov no tiene ningún medicamento por devolver
          if (!medicamentosStore._medicamentos_tabla.some(a => a.DelivrdQty)) return toast.add({ severity: 'error', summary: 'Error', detail: 'La orden debe tener al menos un medicamento entregado para generar devoluciones', life: 6000 })
          // La ov esta cerrada y sincronizado con SAP
          if (ordenesStore._orden.sincronizacionSap && ordenesStore._orden.sincronizacionSap.Status === 2 && ordenesStore._orden.DocStatus === 1) return toast.add({ severity: 'error', summary: 'Error', detail: 'No se pueden generar devoluciones en esta orden ya que se encuentra sincronizada con SAP y esta cerrada', life: 6000 })
          if (ordenesStore._bodega_ppal_diferente) return toast.add({ severity: 'error', summary: 'Error', detail: 'No puedes generar devoluciones en esta orden ya que la bodega no coincide con tu bodega principal', life: 6000 })
          router.push({ name: 'pharmasan.ventas.ov.ver.devolucion', params: { id: ordenesStore._orden.Id } })
        }
        if (tipo === 'editar') {
          // Si la orden de venta se encuentra cancelada
          if (ordenesStore._orden.DocStatus === 2) return toast.add({ severity: 'error', summary: 'Error', detail: 'La orden de venta se encuentra cancelada', life: 6000 })
          // Si la orden de venta ya esta sincronizada en SAP
          // if (ordenesStore._orden.sincronizacionSap && ordenesStore._orden.sincronizacionSap.Status === 2) return toast.add({ severity: 'error', summary: 'Error', detail: 'La orden de venta ya se encuentra sincronizada en SAP, si desea editarla cancele la ov en SAP', life: 6000 })
          router.push({ name: 'pharmasan.ventas.ov.editar', params: { id: ordenesStore._orden.Id } })
        }
        if (tipo === 'cancelar') {
          // Si la ov no esta cancelada
          if (ordenesStore._orden.DocStatus === 2) return toast.add({ severity: 'error', summary: 'Error', detail: 'La orden de venta ya se encuentra cancelada', life: 6000 })
          // Si el usuario conectado no cuenta con la bodega principal de la crecion de la ov
          if (ordenesStore._bodega_ppal_diferente) return toast.add({ severity: 'error', summary: 'Error', detail: 'La orden no se puede cancelar ya que la bodega no coincide con su bodega principal', life: 6000 })
          // Si la orden de venta ya esta sincronizada en SAP
          if (ordenesStore._orden.sincronizacionSap && ordenesStore._orden.sincronizacionSap.Status === 2) return toast.add({ severity: 'error', summary: 'Error', detail: 'La orden de venta ya se encuentra sincronizada en SAP, si desea editarla cancele la ov en SAP', life: 6000 })
          cancelarOrden()
        }
      }
      onMounted(() => {
        ordenesStore.findPagoRecibido(route.params.id)
      })
      return {
        dayjs,
        showUdf,
        expandedRows,
        dataMedicamento,
        displayModalInfoMedicamento,
        columnasTabla,
        listadoMedicamentos,
        showMedicamentos,
        showHistorialDocumento,
        events1,
        displayHistorialDocumento,
        persona,
        lotesEntregar,
        vista,
        cancelarOrden,
        DescargarPdf,
        DescargarNoEntrPdf,
        DescargarPdfConf,
        displayModalMedicamentoPendiente,
        displayModalMedicamentosPendientesListado,
        displayModalImprimirOrden,
        lineasImprimir,
        lotePendienteSeleccionado,
        cuotas,
        pagoSincronizado,
        listaMedicamentos,
        ordenesStore,
        medicamentosStore,
        validacionBotones,
        validacionDevolucion
      }
    }
  }
</script>
